import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import utc from "dayjs/plugin/utc";
import { assertIsDefined } from "shared/utils/utils";
import { monthDaySchema } from "shared/validation/monthDaySchema";

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export function getIsValidDate(date: Date): boolean {
  return date instanceof Date && !isNaN(date.getTime());
}

export const isDateInThePast = (date: Date) => {
  const now = new Date();
  return now > date;
};

export const getDaysFromToday = (date: Date) => {
  const dayjsInstance = dayjs(date).utc().startOf("day");
  const today = dayjs().utc().startOf("day");
  const diffDays = dayjsInstance.diff(today, "days");
  return diffDays;
};

export function isBusinessDay(date: Date) {
  // 0 - Sunday, 6 - Saturday
  const day = dayjs(date).utc().day();
  return day > 0 && day < 6;
}

function isSunday(date: Date) {
  // 6 - Sunday
  const day = date.getDay();
  return day === 6;
}

export const getBusinessDaysFromToday = (date: Date) => {
  const dayjsInstance = dayjs(date).utc().startOf("day");
  const today = dayjs().utc().startOf("day");
  const diff = dayjsInstance.diff(today, "days");
  const diffAbsolute = Math.abs(diff);
  const pastDate = diff < 0;
  const diffAccountingForWeekendStart =
    !isBusinessDay(today.toDate()) && pastDate ? diff + 1 : diff;
  let numberOfWeekendDaysBetweenStartAndEnd = 0;
  for (let i = 1; i < diffAbsolute + 1; i++) {
    const relevantDay = pastDate ? today.subtract(i, "days") : today.add(i, "days");
    if (!isBusinessDay(relevantDay.toDate())) {
      numberOfWeekendDaysBetweenStartAndEnd++;
    }
  }

  if (isSunday(today.toDate()) && diff === -1) {
    return diffAccountingForWeekendStart;
  }

  // This augments the diff to remove the weekend days from the calculation
  if (pastDate) {
    return diffAccountingForWeekendStart + numberOfWeekendDaysBetweenStartAndEnd;
  } else {
    return diffAccountingForWeekendStart - numberOfWeekendDaysBetweenStartAndEnd;
  }
};

export const getDiffMinutes = (date: Date) => {
  const dayjsInstance = dayjs(date).utc();
  const diff = dayjs().utc().diff(dayjsInstance, "minutes");
  return diff;
};

export const getDiffDaysFromDates = (dateStart: Date, dateEnd: Date) => {
  const dayjsStart = dayjs(dateStart).utc().startOf("day");
  const dayjsEnd = dayjs(dateEnd).utc().startOf("day");
  const diff = dayjsStart.diff(dayjsEnd, "days");
  return diff;
};

export const isToday = (date: Date) => {
  const daysFromToday = getDaysFromToday(date);
  return daysFromToday === 0;
};

export const getIsCoverageActive = (policyEffective: Date | null | undefined) => {
  const isCoverageActive = policyEffective
    ? isToday(policyEffective) || isDateInThePast(policyEffective)
    : null;
  return isCoverageActive;
};

export const getDateStringFromShortString = (monthAndDay: string): string => {
  // Fix year to make sure Feb 29 is a valid date
  return dayjs(`${monthAndDay}/2004`, "MM/DD/YYYY").format("MMMM DD");
};

/**
 * Given a `Date` object, returns another `Date` object
 * in the next business day.
 *
 * e.g. Wed Jul 27 2022 10:36:27 => Thu Jul 28 2022 10:36:27
 * e.g. Fri Jul 29 2022 10:36:27 => Mon Aug 01 2022 10:36:27
 *
 * @param date
 * @returns
 */
export function getNextBusinessDay(date: Date) {
  const dateDate = date.getDate();
  const dateDay = date.getDay();

  const nextBusinessDay = new Date(date);

  if (dateDay === 5 || dateDay === 6) {
    // Friday and Saturday
    nextBusinessDay.setDate(dateDate + 3);
  } else if (dateDay === 0) {
    // Sunday
    nextBusinessDay.setDate(dateDate + 2);
  } else {
    nextBusinessDay.setDate(dateDate + 1);
  }

  return nextBusinessDay;
}

export function getIsTodayAfterNextBusinessDayOf(date: Date) {
  const nextBusinessDay = getNextBusinessDay(date);
  const now = new Date();
  const isTodayAfterNextBusinessDayOf = now > nextBusinessDay;
  return isTodayAfterNextBusinessDayOf;
}

/**
 * Returns true if the difference between the dates exceeds 30 days.
 * Inputs must be in format MM/DD.
 * iF endDate appears to be before startDate (e.g. start: 12/10, end: 01/25),
 * assume that the endDate is in the following year.
 *
 * @param startDate MM/DD
 * @param endDate MM/DD
 */
export function getExceeds30Days(startDate: string, endDate: string) {
  try {
    monthDaySchema.validateSync(startDate);
    monthDaySchema.validateSync(endDate);
  } catch {
    return null;
  }

  const [startDateMonth, startDateDay] = startDate.split("/");
  const [endDateMonth, endDateDay] = endDate.split("/");

  assertIsDefined(startDateMonth, "startDateMonth");
  assertIsDefined(startDateDay, "startDateDay");
  assertIsDefined(endDateMonth, "endDateMonth");
  assertIsDefined(endDateDay, "endDateDay");

  // any non-leap year
  // following year must also be non-leap
  const referenceYear = 2001;

  const startDateDayjs = dayjs()
    .year(referenceYear)
    .month(Number.parseInt(startDateMonth, 10) - 1)
    .date(Number.parseInt(startDateDay, 10));
  const endDateDayjs = dayjs()
    .year(referenceYear)
    .month(Number.parseInt(endDateMonth, 10) - 1)
    .date(Number.parseInt(endDateDay, 10));

  const diff = endDateDayjs.isSameOrAfter(startDateDayjs)
    ? endDateDayjs.diff(startDateDayjs, "days")
    : endDateDayjs.add(1, "year").diff(startDateDayjs, "days");

  const exceeds30Days = diff > 30;
  return exceeds30Days;
}

type DateRange = {
  startDate: Date;
  endDate: Date;
};

export const getPreviousMonthRange = (inputDate: Date): DateRange => {
  const date = new Date(inputDate.getTime());
  date.setDate(1);
  date.setMonth(date.getMonth() - 1);

  const startDate = new Date(date.getTime());

  date.setMonth(date.getMonth() + 1);
  date.setDate(0);

  const endDate = new Date(date.getTime());

  return {
    startDate,
    endDate,
  };
};
