import dayjs from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import isSameOrAfterPlugin from "dayjs/plugin/isSameOrAfter";
import isSameOrBeforePlugin from "dayjs/plugin/isSameOrBefore";
import utc from "dayjs/plugin/utc";
import { formatDateRelative } from "shared/utils/format";

dayjs.extend(utc);
dayjs.extend(isBetweenPlugin);
dayjs.extend(isSameOrAfterPlugin);
dayjs.extend(isSameOrBeforePlugin);

const timezoneAdjustedDayjs = (d: dayjs.Dayjs) => d.subtract(d.utcOffset(), "minute");

export const timezoneAdjustedDate = (date: Date) => timezoneAdjustedDayjs(dayjs(date)).toDate();

export const isBefore = (date: Date) => dayjs().isBefore(timezoneAdjustedDate(date));

export const isAfter = (date: Date) => dayjs().isAfter(timezoneAdjustedDate(date));

export const isSameOrAfter = (date: Date) => dayjs().isSameOrAfter(timezoneAdjustedDate(date));

export const isBetween = (startDate: Date, endDate: Date) =>
  dayjs().isBetween(
    timezoneAdjustedDate(startDate),
    timezoneAdjustedDate(addDays(endDate, 1)),
    undefined,
    "[)",
  );

export const daysUntil = (date: Date) =>
  timezoneAdjustedDayjs(dayjs(date)).diff(dayjs().startOf("day"), "days");

export const addDays = (date: Date, days: number) => dayjs(date).add(days, "day").toDate();

export const buildDate = (policyEffective: Date) => {
  const dayjsInstance = dayjs(policyEffective).utc();
  const month = dayjsInstance.format("MMMM");
  const day = dayjsInstance.date();
  const daysDiffString = formatDateRelative(policyEffective);
  return { month, day, daysDiffString };
};

export const isPeakSeason = () => {
  const now = dayjs();
  const peakSeasonStart = dayjs()
    .month(10)
    .date(20)
    .year(now.month() <= 1 ? now.year() - 1 : now.year());
  const peakSeasonEnd = dayjs()
    .month(0)
    .date(31)
    .year(now.month() >= 10 ? now.year() + 1 : now.year());

  return (
    now.startOf("day").isSameOrAfter(peakSeasonStart.startOf("day")) &&
    now.endOf("day").isSameOrBefore(peakSeasonEnd.endOf("day"))
  );
};
