import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { DateTime, Duration, FixedOffsetZone } from "luxon";
import pluralize from "pluralize";
import { assertIsDefined } from "shared/utils/utils";
import { monthDaySchema } from "shared/validation/monthDaySchema";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const uiDateWithYear = "LLLL d, y";
const uiDateWithYear2 = "LLLL dd, y";

/**
 * @param input
 * @returns e.g. 2025
 */
export const formatYearDate = (value?: Date) =>
  dayjs(value || new Date())
    .utc()
    .format("YYYY");

/**
 * @param input
 * @returns e.g. 10/30/2021 in UTC timezone
 */
export const formatDate = (input: Date) => dayjs(input).utc().format("M/D/YYYY");

/**
 * @param input
 * @returns e.g. 10/14/1983, 9:30 AM EDT
 * @description Returns short datetime string with the local timezone of the user (when run on client) or the server (when run on server)
 */
export const formatDateTimeLocal = (input: Date) => dayjs(input).format("M/D/YYYY, h:mm A z");

/**
 * @param input
 * @returns e.g. "2 days ago"
 */
export function formatDateRelative(input: Date) {
  const dayjsInstance = dayjs(input).utc();
  const diff = dayjs().utc().startOf("day").diff(dayjsInstance, "days");
  const rtf1 = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
  const daysDiffString = rtf1.format(-diff, "day");
  return daysDiffString;
}

/**
 * @param input
 * @returns e.g. Oct 14, 1983, 9:30 AM
 * @description Returns med datetime string with local hour
 */
export const formatToMediumDateAndTime = (input: Date) =>
  DateTime.fromJSDate(input).toLocaleString({
    ...DateTime.DATETIME_MED,
  });

/**
 * @param input
 * @returns e.g. 9:30 AM EDT
 * @description Returns short time string with the local timezone of the user (when run on client) or the server (when run on server)
 */
export const formatTimeLocal = (input: Date) => {
  const datetime = DateTime.fromJSDate(input).toLocaleString({
    ...DateTime.DATETIME_SHORT,
    timeZoneName: "short",
  });
  const time = datetime.split(",")[1]?.trim() ?? "";
  return time;
};

/**
 * @param input
 * @returns e.g. 10/14/1983
 * @description Returns short date string with the local timezone of the user (when run on client) or the server (when run on server)
 */
export const formatShortDateTimeLocal = (input: Date) =>
  DateTime.fromJSDate(input).toLocaleString({
    ...DateTime.DATE_SHORT,
  });

/**
 * @param input
 * @returns e.g. Oct 31
 */
export const formatDateShortMonth = (input: Date) => {
  return DateTime.fromJSDate(input, { zone: FixedOffsetZone.utcInstance }).toFormat("LLL d");
};

/**
 * @param input
 * @returns e.g. Wed Jan 5 2022 with the local timezone of the user
 */
export const formatDateWeekDayShortMonthWithYear = (input: Date) => {
  return DateTime.fromJSDate(input).toLocal().toFormat("ccc LLL d y");
};

/**
 * @param input
 * @returns e.g. Sat Jan 2, 2021, 7:00 AM EST
 */
export const formatDateWithTimeAndTimezone = (input: Date) => {
  return DateTime.fromJSDate(input).toFormat("ccc LLL d, y, h:mm a ZZZZ");
};

/**
 * @param input
 * @returns e.g. January 2
 */
export const formatDateFullMonth = (input: Date) => {
  return DateTime.fromJSDate(input, { zone: FixedOffsetZone.utcInstance }).toFormat("LLLL d");
};

/**
 * @param input
 * @returns e.g. October 1, 2021
 */
export const formatDateFullMonthWithYear = (input: Date) =>
  DateTime.fromJSDate(input, { zone: FixedOffsetZone.utcInstance }).toFormat(uiDateWithYear);

/**
 * @param input
 * @returns e.g. October 01, 2021
 */
export const formatDateLongFullMonthWithYear = (input: Date) =>
  DateTime.fromJSDate(input, { zone: FixedOffsetZone.utcInstance }).toFormat(uiDateWithYear2);

/**
 * @param input
 * @param includeYear
 * @returns e.g. "an October 31, 2021", "a September 31, 2021"
 */
export const formatDateFullMonthWithArticle = (input: Date, includeYear: boolean) => {
  const currentMonth = input.getMonth();
  // April, August, and October need "an" as an article
  const monthsForAnArticle = [2, 6, 8];
  const article = monthsForAnArticle.includes(currentMonth) ? "an" : "a";
  return `${article} ${
    includeYear ? formatDateFullMonthWithYear(input) : formatDateFullMonth(input)
  }`;
};

export const minutesToTimeFormat = (totalMinutes: number) => {
  if (totalMinutes <= 0) return "";

  const h = Math.floor(totalMinutes / 60);
  const m = totalMinutes % 60;

  const hours = pluralize("hour", h);
  const minutes = pluralize("minute", m);

  if (h && m) return `${h} ${hours} and ${m} ${minutes}`;
  if (h) return `${h} ${hours}`;
  if (m) return `${m} ${minutes}`;
  return "";
};

export const formatDateRangeUI = (start: Date, end: Date) => {
  const s = DateTime.fromJSDate(start, { zone: FixedOffsetZone.utcInstance });
  const e = DateTime.fromJSDate(end, { zone: FixedOffsetZone.utcInstance });
  const uiDateFormat = "LLLL d";
  if (s.year !== e.year) {
    return s.toFormat(uiDateWithYear) + " - " + e.toFormat(uiDateWithYear);
  } else if (s.month !== e.month) {
    return s.toFormat(uiDateFormat) + " - " + e.toFormat(uiDateFormat) + ", " + String(e.year);
  } else {
    return s.toFormat(uiDateFormat) + " - " + String(e.day) + ", " + String(e.year);
  }
};

/**
 * @param input
 * @returns e.g. { date: 'Oct 1', time: '10AM EST' }
 */
export const formatRoundedDateAndTime = (input: Date) => {
  const datetime = DateTime.fromJSDate(input, { zone: FixedOffsetZone.utcInstance });
  const roundedDateTime = datetime.plus({
    minutes: datetime.minute > 30 ? 60 - datetime.minute : -datetime.minute,
  });

  const date = roundedDateTime.setZone("America/New_York").toFormat("LLL d");
  const time = roundedDateTime.setZone("America/New_York").toFormat("ha 'EST'");

  return {
    date,
    time,
  };
};

/**
 * @param input
 * @returns e.g. Oct-1-2021 13-07-01, Oct-1-2021 06-30-59
 */
export const formatDateTimeWithoutSlash = (input: Date): string => {
  const datetime = DateTime.fromJSDate(input, { zone: FixedOffsetZone.utcInstance });
  const formattedDate = datetime.setZone("America/New_York").toFormat("LLL-d-y HH-mm-ss");

  return formattedDate;
};

/**
 * Returns the date in MM/DD format
 * @returns e.g. 03/25
 */
export const formatDateToMMDD = (date: Date): string => {
  return date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
  });
};

/**
 * Returns the date in YYYY-MM-DD format
 * @returns e.g. 2025-03-25
 */
export const formatDateOnlyToISOString = (date: Date): string => {
  return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
};

/**
 * @param monthDay 12/31
 * @returns December 31st
 */
export function formatMonthDayToMonthOrdinal(monthDay: string): string;
export function formatMonthDayToMonthOrdinal(monthDay: `${number}/${number}`): string;
export function formatMonthDayToMonthOrdinal(monthDay: string) {
  const monthDayRegex = /^(?<month>\d{1,2})\/(?<day>\d{1,2})$/;
  if (!monthDayRegex.test(monthDay)) {
    throw new TypeError(
      `Argument must be in the format "MM/DD" (e.g. 12/31). Provided: ${monthDay}`,
    );
  }
  monthDaySchema.validateSync(monthDay);

  const [monthStr, dayStr] = monthDay.split("/");
  assertIsDefined(monthStr, "monthStr");
  assertIsDefined(dayStr, "dayStr");
  const month = Number.parseInt(monthStr, 10);
  const day = Number.parseInt(dayStr, 10);

  const pluralRules = new Intl.PluralRules("en-US", { type: "ordinal" });
  const suffixes: Record<Intl.LDMLPluralRule, string> = {
    zero: "",
    one: "st",
    two: "nd",
    few: "rd",
    many: "",
    other: "th",
  };
  const rule = pluralRules.select(day);
  const suffix = suffixes[rule];

  const dateFormatter = new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "numeric",
  });
  const REFERENCE_YEAR = 2024; // 2024 is a leap year so it will allow Feb 29
  const date = new Date(REFERENCE_YEAR, month - 1, day);
  const formattedDate = dateFormatter.format(date) + suffix;
  return formattedDate;
}

/**
 * @param ms
 * @returns e.g. 5:00s
 */
export function formatDuration(ms: number) {
  const duration = Duration.fromMillis(ms).toFormat(`m:ss's'`);
  return duration;
}

type KnownEntity = {
  firstName?: string | null;
  lastName?: string | null;
};

export const formatFullName = (entity: KnownEntity | undefined): string =>
  entity ? `${entity.firstName?.trim() ?? ""} ${entity.lastName?.trim() ?? ""}`.trim() : "";

export const extractFullName = (fullName: string): { firstName: string; lastName: string } => {
  const [firstName, ...lastName] = fullName.split(" ");
  return {
    firstName: firstName ?? "",
    lastName: lastName.join(" "),
  };
};

export type ListFormatOptions = {
  type: "conjunction" | "disjunction" | "unit";
  style: "long" | "short" | "narrow";
};

const defaultListFormat: ListFormatOptions = {
  type: "conjunction",
  style: "long",
};

export function listFormat(wordArray: string[], options: ListFormatOptions = defaultListFormat) {
  const list: string = new Intl.ListFormat("en-US", options).format(wordArray);
  return list;
}
